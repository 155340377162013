/* src/App.css */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

button {
  cursor: pointer;
}
